import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import GoogleImage from "../../assets/images/vihara/google.png"
import Apple from "../../assets/images/vihara/appleImage.png"
import "./SaveListingModal.css"
const SaveListingModal = ({ open, onClose }) => {
  const googleAuth = () => {
    window.open(
      "https://viharabackend.onrender.com/auth/google/callback",
      "_self"
    );
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="save-listing-title"
    >
      <Box sx={style}>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="save-listing-title" variant="h6" component="h2">
          <FontAwesomeIcon style={{ color: "red", marginRight: "10px", marginBottom: "-2px" }} icon={faHeart} />
          <span>Save this Listing</span>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Enter your e-mail to save this property to your favorites and to keep track of price and status changes.
        </Typography>
        <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
          <input
            className="signup-input"
            type="text"
            style={{ marginBottom: "0px" }}
            placeholder='Email Adress *'
          />
          <button style={{ width: "34%", paddingTop: "12px", paddingBottom: "12px",marginTop:"0px" }} className="signup-button" type="submit">Register</button>
        </div>
        <Typography sx={{ mt: 2, mb: 1 }}>
          Already got an account? <a href="/login" style={{
            cursor: 'pointer',
            color: 'rgb(17, 139, 244)',
            fontWeight: 'bold',
            textDecoration: 'none'
          }} >Sign In</a>
        </Typography>
        <div style={{ height: "1px", width: "100%", backgroundColor: "#eee", marginBottom: "10px", marginTop: "10px" }}></div>
        <Typography>Or, sign in with your Google Account:</Typography>
        <div style={{ width: "100%" }} className='continue-with-google' onClick={googleAuth}>
          <img src={GoogleImage} />
          <span>Continue with Google</span>
        </div>
        <div  style={{width:"100%"}}  className="continue-with-google">
            <img src={Apple} />
            <span>Continue with Apple</span>
          </div>
        {/* <Typography sx={{ mt: 2 }}>Or, sign in with your Apple account:</Typography> */}
        {/* <Button fullWidth variant="outlined" startIcon={<img src="apple-icon.svg" alt="Apple" />}>
          Continue with Apple
        </Button> */}
      </Box>
    </Modal>
  );
};

export default SaveListingModal;