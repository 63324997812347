import React, { useState, useRef } from "react";
import "./PurchaseProfile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const MyPurchaseProfiles = () => {
  const [clicked, setClicked] = useState(false);
  const [buyerDataSubmitted, setBuyerDataSubmitted] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    address: "",
    state: "CA",
    city: "",
    zipCode: "",
    email: "",
    phoneNumber: "",
    coBuyer: "No",
  });
  const [errors, setErrors] = useState({
    name: "",
    role: "",
    selfPurchase: "",
  });
  const nameRef = useRef();

  const handleContinueSubmitButton = () => {
    const name = nameRef.current?.value.trim();
    const role = document.querySelector("input[name='role']:checked")?.value;
    const selfPurchase = document.querySelector(
      "input[name='selfPurchase']:checked"
    )?.value;

    const newErrors = {
      name: !name ? "Invalid name" : "",
      role: !role ? "Please choose an option" : "",
      selfPurchase: !selfPurchase ? "Please choose an option" : "",
    };

    setErrors(newErrors);

    // If there are no errors, save the data and mark it as submitted
    if (!newErrors.name && !newErrors.role && !newErrors.selfPurchase) {
      setSubmittedData({ name, role, selfPurchase });
      setBuyerDataSubmitted(true);
    }
  };

  return (
    <div className="purchase-profile-container">
      <div className="purchase-profile-header">
        <h4 className="portal-purchase-header">My Purchase Profiles</h4>
      </div>
      <div className="purchase-profile-add" onClick={() => setClicked(true)}>
        <div className="purchase-profile-add-box">
          <span className="purchase-profile-add-icon">
            <FontAwesomeIcon
              style={{
                color: clicked ? "#d6e1ec" : "rgb(3 132 251)",
                fontSize: "24px",
              }}
              icon={faPlus}
            />
          </span>
          <span
            className="purchase-profile-add-text"
            style={{ color: clicked ? "#d6e1ec" : "rgb(3 132 251)" }}
          >
            Add New Profile
          </span>
        </div>
      </div>
      <div
        style={{ display: clicked ? "" : "none" }}
        className="purchase-profile-save-time"
      >
        <h3>Save Time, Bid Faster!</h3>
        <p>
          Use the information on this buyer information form to create a
          purchase profile. Using a purchase profile will make bidding on
          another property easier and faster.
        </p>
        <div className="purchase-profile-input">
          <label htmlFor="profile-name">Purchase Profile Name</label>
          <div className="purchase-profile-character-limit-container">
            <input
              type="text"
              id="profile-name"
              maxLength="16"
              className="purchase-profile-input-field"
              ref={nameRef}
            />
            <span className="purchase-profile-char-limit">
              Character limit: 16
            </span>
          </div>
        </div>
        {errors.name && (
          <span className="purchase-profile-error">{errors.name}</span>
        )}
      </div>
      <div
        style={{ display: clicked ? "" : "none" }}
        className="purchase-profile-section-title"
      >
        <FontAwesomeIcon
          className="purchase-profile-buyer-icon"
          icon={faCircleCheck}
        />{" "}
        <h4>Buyer/ Agent Information</h4>
        <div
          onClick={() => setBuyerDataSubmitted(false)}
          className="edit-buyer-information"
        >
          Edit
        </div>
      </div>
      {buyerDataSubmitted ? (
        <div className="purchase-profile-submitted-info">
          <p>
            <strong>What describes you best ? : </strong> {submittedData.role}
          </p>
          <p>
            <strong>Are you represented by an Agent ? :</strong>{" "}
            {submittedData.selfPurchase === "yes" ? "Yes" : "No"}
          </p>
        </div>
      ) : (
        <div
          style={{ display: clicked ? "" : "none" }}
          className="purchase-profile-agent-info"
        >
          <div className="purchase-profile-radio-group">
            <label>What describes you best? *</label>
            <div className="purchase-radio-input-container">
              <div>
                <input type="radio" id="buyer" name="role" value="Buyer" />
                <label htmlFor="buyer">Buyer</label>
              </div>
              <div>
                <input type="radio" id="agent" name="role" value="Agent" />
                <label htmlFor="agent">Agent</label>
              </div>
            </div>
            {errors.role && (
              <span className="purchase-profile-error">{errors.role}</span>
            )}
          </div>
          <div className="purchase-profile-radio-group">
            <label>Are you represented by an Agent? *</label>
            <div className="purchase-radio-input-container">
              <div>
                <input type="radio" id="yes" name="selfPurchase" value="yes" />
                <label htmlFor="yes">Yes</label>
              </div>
              <div>
                <input type="radio" id="no" name="selfPurchase" value="no" />
                <label htmlFor="no">No</label>
              </div>
            </div>
            {errors.selfPurchase && (
              <span className="purchase-profile-error">
                {errors.selfPurchase}
              </span>
            )}
          </div>
          <button
            onClick={handleContinueSubmitButton}
            className="purchase-profile-continue-btn"
          >
            Continue
          </button>
        </div>
      )}
      <div
        style={{ display: clicked ? "" : "none" }}
        className="purchase-profile-section-title"
      >
        <FontAwesomeIcon
          className="purchase-profile-buyer-icon"
          icon={faCircleCheck}
        />{" "}
        <h4> Buyer's Information</h4>
      </div>
      {buyerDataSubmitted && (
        <div
          style={{ display: clicked ? "" : "none" }}
          className="purchase-profile-agent-info"
        >
          <form className="buyer-information-form">
            <p>
              As this profile information will be used to bind a legal contract,
              please ensure to add information correctly.
            </p>
            <div className="buyer-information-form-group">
              <h5>Purchase is being made on behalf of:</h5>
              <div>
                <input
                  type="radio"
                  name="purchaseType"
                  value="Individual"
                  defaultChecked
                />
                <label>Individual</label>
                <input
                  type="radio"
                  name="purchaseType"
                  value="Business Entity"
                />
                <label>Business Entity</label>
              </div>
            </div>

            <div className="buyer-information-form-group">
              

                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                />
              {errors.firstName && (
                <span className="buyer-information-error">
                  {errors.firstName}
                </span>
              )}
            </div>

            <div className="buyer-information-form-group">
                <input type="text" name="lastName" value={formData.lastName} />
              {errors.lastName && (
                <span className="buyer-information-error">
                  {errors.lastName}
                </span>
              )}
            </div>

            <div className="buyer-information-form-group">
                <input
                  type="text"
                  name="middleName"
                  value={formData.middleName}
                />
            </div>

            <div className="buyer-information-form-group">
                <input type="text" name="address" value={formData.address} />
            </div>

            <div className="buyer-information-form-group">
                <select name="state" value={formData.state}>
                  <option value="CA">CA</option>
                  <option value="NY">NY</option>
                  <option value="TX">TX</option>
                </select>
            </div>

            <div className="buyer-information-form-group">
                <input type="text" name="city" value={formData.city} />
              {errors.city && (
                <span className="buyer-information-error">{errors.city}</span>
              )}
            </div>

            <div className="buyer-information-form-group">
                <input type="text" name="zipCode" value={formData.zipCode} />
              {errors.zipCode && (
                <span className="buyer-information-error">
                  {errors.zipCode}
                </span>
              )}
            </div>
            <div className="buyer-information-form-group">
                <input type="email" name="email" value={formData.email} />
              {errors.email && (
                <span className="buyer-information-error">{errors.email}</span>
              )}
            </div>

            <div className="buyer-information-form-group">
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                />
              {errors.phoneNumber && (
                <span className="buyer-information-error">
                  {errors.phoneNumber}
                </span>
              )}
            </div>
            <button type="submit" className="buyer-information-button">
              Verify
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default MyPurchaseProfiles;
