import React from 'react';
import './MyBids.css';
import { useState } from 'react';
const MyBids = () => {
  const [text,setText]=useState("bids");
  const handleClick=()=>{
    if(text==="bids"){
      setText("history");
    }
    else{
      setText("bids")
    }
  }
  return (
    <div className="portal-my-bids-container">
      <div className="portal-my-bids-header">
        <h2 className='portal-profile-header'>My Bids</h2>
        <span onClick={()=>handleClick()} className="portal-my-bids-history-link"> {text==="bids"?<>History</>:<>Go Back</>} </span>
      </div>
      <div className="portal-my-bids-content">
      <div className="portal-my-bids-last-updated">
          Last Updated: Sep 7, 2024 16:46:44 ET
        </div>
       {text==="bids"?<p>You don’t have any properties saved.</p>:<p>You do not have previous transaction on any of the properties</p>} 
      </div>
    </div>
  );
};

export default MyBids;
