import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import './OtpVerification.css';
import NavBackground from '../../includes/navBackground';
import signUpImage from "../../../assets/images/discoverComponent/discoverComponent.png"
import { useLocation } from "react-router-dom";
import OtpInput from "./OtpInput"
const OtpVerification = () => {
    const location = useLocation();
    const { user,token } = location.state || {};  // destructuring state
    return (
        <>
            <Helmet>
                <title>Login to Vihara AI - Access Your Real Estate Auction Account</title>
                <meta name="description" content="Access your Vihara AI account to participate in AI-driven real estate auctions. Log in now for property listings and bids." />
                <meta name="keywords" content="Login Vihara AI, account access, real estate auctions, user login" />
            </Helmet>
            <NavBackground />
            <div className="otp-verification-container">
                <div className="otp-verification-form-container">
                    {/* <div className="login-logo"><img src={Images.logoOnDark} alt="Vihara AI Logo" /></div> */}
                    <div className='phone-number-verification'>Phone Number Verification</div>
                    <div className='otp-notification'>
                        Enter the verfication code sent to the phone number ending in</div>
                    <div className='otp-mobile-number'>(***) ***-{user.businessPhone.slice(-4)}</div>
                    {/* <h2 className="login-title">Sign In</h2> */}
                     <OtpInput user={user} token={token} />
                    <div className='otp-notification'>Didn't get the verification code?<span style={{ color: "#118bf4" }}> Click here</span> to generate a new one or check your  <span style={{ color: "#118bf4" }}>profile</span> to confirm your phone number is correct.

                    </div>
                </div>
                <div className="otp-verification-image-container">
                    <img src={signUpImage} />
                </div>
            </div>

        </>
    );
};

export default OtpVerification;
