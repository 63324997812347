import React, { useEffect } from "react";
import "./CommonBuy.css";
import NavBackground from "../includes/navBackground";
import BuyNavbar from "./BuyNavbar/BuyNavbar";
import PropertySample from "./PropertySample/PropertySample";
import randomData from "./randomData";
import { useFilterContext } from "../../ContextProvider/Context";
import BuyNavbarSmallerScreen from "./BuyNavbar/BuyNavbarSmallerScreen";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Buys({}) {
  const query = useQuery();
  window.scrollTo(0, 0);
  const isScreenSmall = useMediaQuery("(max-width:1150px)");
  const [showMap, setShowMap] = useState(true);
  const products = useSelector((state) => state.property.Properties);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const {
    propertyType,
    setPropertyType,
    assetType,
    setAssetType,
    buyingType,
    setBuyingType,
    condition,
    setCondition,
    featured,
    setFeatured,
    moreFilters,
    setMoreFilters,
    handleClearFilters,
    handleSelectChange,
    setStates,
    states
  } = useFilterContext();
  useEffect(() => {
    setAssetType(query.getAll("Asset Type"));
    setBuyingType(query.getAll("Buying Type"));
    setPropertyType(query.getAll("Property Type"));
    setCondition(query.getAll("Condition"));
    setMoreFilters(query.getAll("More Filters"));
    setStates(query.getAll('states'));
  }, []);
  useEffect(() => {
    const filtered = products.filter((product) => {
      let matchesAssetType = true;
      let matchesBuyingType = true;
      let matchesPropertyType = true;
      let matchesCondition = true;
      let matchesState=true;
      if (assetType.length) {
        if (assetType[0] === "Bank Owned")
          matchesAssetType = product.propertyType === "Reo Bank Owned";
        else if (assetType[0] ==="Foreclosure Homes")
          matchesAssetType = product.propertyType === "Foreclosure Sale";
        else if (assetType[0] === "Short Sale")
          matchesAssetType = product.propertyType === "Short Sale";
      }
      if (buyingType.length)
        matchesBuyingType = buyingType.includes(product.buyingType);
      if (condition.length)
        matchesCondition = condition.includes(product.condition);
      if(states.length){
        matchesState=states.includes(product.state);
        console.log(product.state);
        console.log(states);
      }
      return (
        matchesState&&
        matchesAssetType &&
        matchesBuyingType &&
        matchesPropertyType &&
        matchesCondition
      );
    });
    console.log(filtered);
    setFilteredProducts(filtered);
  }, [products, assetType, buyingType, propertyType, condition, moreFilters,states]);
  const handleShowMap = () => {
    const mapWidthController = document.querySelector(".property-location");
    const propertyCardsContainer = document.querySelector(".property-cards");
    const propertyCardController =
      document.getElementsByClassName("property-card");
    if (showMap === true) {
      mapWidthController.style.width = "0%";
      propertyCardsContainer.style.width = "100%";
      propertyCardsContainer.style.display = "flex";
      propertyCardsContainer.style.flexDirection = "row";
      propertyCardsContainer.style.alignItems = "start";
      propertyCardsContainer.style.justifyContent = "center";
      Array.from(propertyCardController).forEach((card) => {
        card.style.flexDirection = "column";
        card.style.width = "300px";
        card.style.height = "400px";
      });
      setShowMap(false);
    } else {
      if (window.innerWidth >= 1220) {
        mapWidthController.style.width = "40%";
        propertyCardsContainer.style.width = "65%";
        propertyCardsContainer.style.display = "flex";
        propertyCardsContainer.style.flexDirection = "row";
        propertyCardsContainer.style.justifyContent = "center";
        propertyCardsContainer.style.alignItems = "flex-start";
        propertyCardsContainer.style.flexWrap = "wrap";
        Array.from(propertyCardController).forEach((card) => {
          card.style.flexDirection = "row";
          card.style.width = "96%";
          card.style.height = "212px";
        });
      } else {
        mapWidthController.style.width = "100%";
        propertyCardsContainer.style.width = "0px";
        propertyCardsContainer.style.display = "none";
      }
      setShowMap(true);
    }
  }
  return (
    <div className="common-buy-container">
      <NavBackground />
      {isScreenSmall ? (
        <BuyNavbarSmallerScreen
          showMap={showMap}
          handleShowMap={handleShowMap}
        />
      ) : (
        <BuyNavbar showMap={showMap} handleShowMap={handleShowMap} />
      )}
      <PropertySample
        showMap={showMap}
        handleShowMap={handleShowMap}
        products={filteredProducts}
        selectedValues={states}
        onSelectState={handleSelectChange(setStates)}
      />
    </div>
  );
}

export default Buys;
