import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import './sellproperties.css';
import NavBackground from '../includes/navBackground'
import BAckgroundIMage from "./assets/back.png"
import CommonHeroSection from '../HomePage/CommonHeroSection/CommonHeroSection'
import { Modal, Box, Typography, Button, TextField, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useRef } from 'react';
import Sell from "./assets/SellImage.png"
const prices = [
    "$0", "$50,000", "$100,000", "$150,000", "$200,000", "$250,000", "$300,000",
    "$350,000", "$400,000", "$450,000", "$500,000", "$550,000", "$600,000",
    "$650,000", "$700,000", "$750,000", "$800,000", "$850,000", "$900,000",
    "$950,000", "$1.0M", "$2.0M", "$3.0M", "$4.0M", "$5.0M", "$6.0M", "$7.0M",
    "$8.0M", "$9.0M", "$10.0M", "$11.0M", "$12.0M", "$13.0M", "$14.0M", "$15.0M",
    "$16.0M", "$17.0M", "$18.0M"
]
const statesOfAmerica = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
    "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
    "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
    "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota",
    "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada",
    "New Hampshire", "New Jersey", "New Mexico", "New York",
    "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon",
    "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
    "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington",
    "West Virginia", "Wisconsin", "Wyoming"
];

const propertyConditions = [
    "Move-in Ready", "Needs Renovation", "Not Habitable"
];

const propertyTypes = [
    "Single Family", "Multi-Family", "Condo", "Townhouse", "Land"
];

const numberOfPropertiesOptions = [
    "Single Property", "Portfolio"
];

const propertyOccupancyOptions = [
    "Vacant", "Occupied", "Occupied with active tenant"
];

const mortgageExistOptions = [
    "Yes", "No"
];

const relationshipWithPropertyOptions = [
    "Owner", "Co-Owner", "Agent"
];

const sellingTimeOptions = [
    "Immediately", "Within 3 months", "Within 6 months", "More than 6 months"
];
const propertyMarketHistoryOptions = [
    "New to Market", "Currently or has been listed"
]
const TakeAMoment = () => {
    return (
        <div className='take-a-moment-section'>
            <div className="take-a-moment-text">
                Complete the form below and a Vihara team member will reach out shortly about your inquiry.
            </div>
        </div>
    )
}
const SellDetailForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [propertyAddress, setPropertyAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [propertyCondition, setPropertyCondition] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [numberOfProperties, setNumberOfProperties] = useState('');
    const [propertyMarketHistory, setPropertyMarketHistory] = useState('');
    const [propertyOccupancy, setPropertyOccupancy] = useState('');
    const [mortgageExist, setMortgageExist] = useState('');
    const [mortageRange, setMortageRange] = useState('');
    const [minDesiredPrice, setMinDesiredPrice] = useState('');
    const [maxDesiredPrice, setMaxDesiredPrice] = useState('');
    const [relationshipWithProperty, setRelationshipWithProperty] = useState('');
    const [sellingTime, setSellingTime] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState("");
    useEffect(() => {
        const isValid =
            firstName &&
            lastName &&
            email &&
            phoneNumber &&
            propertyAddress &&
            city &&
            state &&
            zip &&
            propertyCondition &&
            propertyType &&
            numberOfProperties &&
            propertyMarketHistory &&
            propertyOccupancy &&
            mortgageExist &&
            mortageRange &&
            minDesiredPrice &&
            maxDesiredPrice &&
            relationshipWithProperty &&
            sellingTime;

        setIsFormValid(isValid);
    }, [
        firstName, lastName, email, phoneNumber, propertyAddress, city, state, zip,
        propertyCondition, propertyType, numberOfProperties, propertyMarketHistory,
        propertyOccupancy, mortgageExist, mortageRange, minDesiredPrice, maxDesiredPrice,
        relationshipWithProperty, sellingTime, openDropdown
    ]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            firstName, lastName, email, phoneNumber, propertyAddress,
            city, state, zip, propertyCondition, propertyType,
            numberOfProperties, propertyMarketHistory, propertyOccupancy,
            mortgageExist, mortageRange, minDesiredPrice, maxDesiredPrice,
            relationshipWithProperty, sellingTime,
            access_key: "9e60b541-0f85-4d9c-9ed8-b7a5f7e723d3"
        };
        try {
            const res = await axios.post("https://api.web3forms.com/submit", data, {
                headers: { "Content-Type": "application/json", Accept: "application/json" },
            });
            if (res.data.success) {
                console.log("Web3Forms Success", res.data);
                event.target.reset();
                setIsModalOpen(true);
            } else {
                console.error("Web3Forms error", res.data);
            }
        } catch (error) {
            console.error("Web3Forms request error", error);
        }
    };
    const handleClose = () => {
        setIsModalOpen(false);
        window.location.reload();
    };
    return (
        <div className="seller-form-main">
            <div className="seller-form-properties-form-container">
                <form onSubmit={handleSubmit} className="seller-form-properties-form">
                    <h2 className="seller-form-properties-form-heading">Your Contact Details</h2>
                    <div className="seller-form-properties-form-group">
                        <div className="seller-form-properties-form-item">
                            <label>First Name<span className="contact-us-modal-required">*</span></label>
                            <input
                                style={{ backgroundColor:(openDropdown === "") || (firstName !== "") ? "" : openDropdown === "firstName" ? "" : "#ffe6e6" ,border: (openDropdown === "") || (firstName !== "") ? "1px solid #ccc" : openDropdown === "firstName" ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                                onFocus={() => setOpenDropdown("firstName")}
                                name="firstName"
                                type="text"
                                className={`seller-form-properties-input`}
                                value={firstName}
                                onChange={(e) => { setFirstName(e.target.value) }}
                                required
                            />
                        </div>
                        <div className="seller-form-properties-form-item">
                            <label>Last Name<span className="contact-us-modal-required">*</span></label>
                            <input
                                style={{ backgroundColor:(openDropdown === "") || (lastName !== "") ? "" : openDropdown === "lastName" ? "" : "#ffe6e6" ,border: (openDropdown === "") || (lastName !== "") ? "1px solid #ccc" : openDropdown === "lastName" ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                                onFocus={() => setOpenDropdown("lastName")}
                                name="lastName"
                                type="text"
                                className={`seller-form-properties-input`}
                                value={lastName}
                                onChange={(e) => { setLastName(e.target.value) }}
                                required
                            />
                        </div>
                    </div>
                    <div className="seller-form-properties-form-group">
                        <div className="seller-form-properties-form-item">
                            <label>Email Address<span className="contact-us-modal-required">*</span></label>
                            <input
                                style={{ backgroundColor:(openDropdown === "") || (email !== "") ? "" : openDropdown === "email" ? "" : "#ffe6e6" ,border: (openDropdown === "") || (email !== "") ? "1px solid #ccc" : openDropdown === "email" ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                                onFocus={() => setOpenDropdown("email")}
                                name="email"
                                type="email"
                                className={`seller-form-properties-input`}
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                required
                            />
                        </div>
                        <div className="seller-form-properties-form-item">
                            <label>Phone Number<span className="contact-us-modal-required">*</span></label>
                            <input
                                style={{ backgroundColor:(openDropdown === "") || (phoneNumber !== "") ? "" : openDropdown === "phoneNumber" ? "" : "#ffe6e6" ,border: (openDropdown === "") || (phoneNumber !== "") ? "1px solid #ccc" : openDropdown === "phoneNumber" ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                                onFocus={() => setOpenDropdown("phoneNumber")}
                                name="phoneNumber"
                                type="text"
                                className={`seller-form-properties-input`}
                                value={phoneNumber}
                                onChange={(e) => { setPhoneNumber(e.target.value) }}
                                required
                            />
                        </div>
                    </div>
                    <h2 className="seller-form-properties-form-heading">Property Information</h2>
                    <div className="seller-form-properties-form-group">
                        <div className="seller-form-properties-form-item">
                            <label>Property Address<span className="contact-us-modal-required">*</span></label>
                            <input
                                style={{ backgroundColor:(openDropdown === "") || (propertyAddress !== "") ? "" : openDropdown === "propertyAddress" ? "" : "#ffe6e6" ,border: (openDropdown === "") || (propertyAddress !== "") ? "1px solid #ccc" : openDropdown === "propertyAddress" ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                                onFocus={() => setOpenDropdown("propertyAddress")}
                                name="propertyAddress"
                                type="text"
                                className={`seller-form-properties-input`}
                                value={propertyAddress}
                                onChange={(e) => { setPropertyAddress(e.target.value) }}
                                required
                            />
                        </div>
                        <div className="seller-form-properties-form-item">
                            <label>City<span className="contact-us-modal-required">*</span></label>
                            <input
                                style={{ backgroundColor:(openDropdown === "") || (city !== "") ? "" : openDropdown === "city" ? "" : "#ffe6e6" ,border: (openDropdown === "") || (city !== "") ? "1px solid #ccc" : openDropdown === "city" ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                                onFocus={() => setOpenDropdown("city")}
                                name="city"
                                type="text"
                                className={`seller-form-properties-input`}
                                value={city}
                                onChange={(e) => { setCity(e.target.value) }}
                                required
                            />
                        </div>
                    </div>
                    <div className="seller-form-properties-form-group">
                        <div className="seller-form-properties-form-item">
                            <label>State<span className="contact-us-modal-required">*</span></label>
                            <CustomDropdown
                                openDropdown={openDropdown}
                                setOpenDropdown={setOpenDropdown}
                                name="state"
                                options={statesOfAmerica}
                                value={state}
                                setValue={setState}
                                identifier={"state"}
                            />
                        </div>
                        <div className="seller-form-properties-form-item">
                            <label>Zip<span className="contact-us-modal-required">*</span></label>
                            <input
                                style={{ backgroundColor:(openDropdown === "") || (zip !== "") ? "" : openDropdown === "zip" ? "" : "#ffe6e6" ,border: (openDropdown === "") || (zip !== "") ? "1px solid #ccc" : openDropdown === "zip" ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                                onFocus={() => setOpenDropdown("zip")}
                                name="zip"
                                type="text"
                                className={`seller-form-properties-input`}
                                value={zip}
                                onChange={(e) => { setZip(e.target.value) }}
                                required
                            />
                        </div>
                    </div>
                    <div className="seller-form-properties-form-group">
                        <div className="seller-form-properties-form-item">
                            <label>Property Condition<span className="contact-us-modal-required">*</span></label>
                            <CustomDropdown
                                openDropdown={openDropdown}
                                setOpenDropdown={setOpenDropdown}
                                name="propertyCondition"
                                options={propertyConditions}
                                value={propertyCondition}
                                setValue={setPropertyCondition}
                                identifier={"propertyCondition"}
                            />
                        </div>
                        <div className="seller-form-properties-form-item">
                            <label>Property Type<span className="contact-us-modal-required">*</span></label>
                            <CustomDropdown
                                openDropdown={openDropdown}
                                setOpenDropdown={setOpenDropdown}
                                name="propertyType"
                                options={propertyTypes}
                                value={propertyType}
                                setValue={setPropertyType}
                                identifier={"propertyType"}
                            />
                        </div>
                    </div>
                    <div className="seller-form-properties-form-group">
                        <div className="seller-form-properties-form-item">
                            <label>Number of Properties<span className="contact-us-modal-required">*</span></label>
                            <CustomDropdown
                                openDropdown={openDropdown}
                                setOpenDropdown={setOpenDropdown}
                                name="numberOfProperties"
                                options={numberOfPropertiesOptions}
                                value={numberOfProperties}
                                setValue={setNumberOfProperties}
                                identifier={"numberOfProperties"}
                            />
                        </div>
                        <div className="seller-form-properties-form-item">
                            <label>Property Market History</label>
                            <CustomDropdown
                                openDropdown={openDropdown}
                                setOpenDropdown={setOpenDropdown}
                                name="propertyMarketHistory"
                                options={propertyMarketHistoryOptions}
                                value={propertyMarketHistory}
                                setValue={setPropertyMarketHistory}
                                identifier={"propertyMarketHistory"}
                            />
                        </div>
                    </div>
                    <div className="seller-form-properties-form-group">
                        <div className="seller-form-properties-form-item">
                            <label>Property Occupancy<span className="contact-us-modal-required">*</span></label>
                            <CustomDropdown
                                openDropdown={openDropdown}
                                setOpenDropdown={setOpenDropdown}
                                name="propertyOccupancy"
                                options={propertyOccupancyOptions}
                                value={propertyOccupancy}
                                setValue={setPropertyOccupancy}
                                identifier={"propertyOccupancy"}
                            />
                        </div>
                        <div className="seller-form-properties-form-item">
                            <label>Does a Mortgage exist?<span className="contact-us-modal-required">*</span></label>
                            <MortageDropdown
                                name="mortgageExist"
                                options={mortgageExistOptions}
                                value={mortgageExist}
                                setMortgageExist={setMortgageExist}
                                mortageRange={mortageRange}
                                setMortageRange={setMortageRange}
                                identifier={"mortgageExist"}
                                setOpenDropdown={setOpenDropdown}
                                openDropdown={openDropdown}
                            />
                        </div>
                    </div>
                    <div className="seller-form-properties-form-group">
                        <div className="seller-form-properties-form-item">
                            <label>Min Desired Price<span className="contact-us-modal-required">*</span></label>
                            <input
                                style={{ backgroundColor:(openDropdown === "") || (minDesiredPrice !== "") ? "" : openDropdown === "minDesiredPrice" ? "" : "#ffe6e6",border: (openDropdown === "") || (minDesiredPrice !== "") ? "1px solid #ccc" : openDropdown === "minDesiredPrice" ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                                onFocus={() => setOpenDropdown("minDesiredPrice")}
                                name="minDesiredPrice"
                                type="text"
                                className={`seller-form-properties-input`}
                                value={minDesiredPrice}
                                onChange={(e) => {
                                    if (e.target.value.length === 0) setMinDesiredPrice('');
                                    else setMinDesiredPrice(Number(e.target.value.replace(/,/g, '')).toLocaleString('en-US'))
                                }}
                                required
                            />
                        </div>
                        <div className="seller-form-properties-form-item">
                            <label>Max Desired Price<span className="contact-us-modal-required">*</span></label>
                            <input
                                style={{ backgroundColor:(openDropdown === "") || (maxDesiredPrice !== "") ? "" : openDropdown === "maxDesiredPrice" ? "" : "#ffe6e6",border: (openDropdown === "") || (maxDesiredPrice !== "") ? "1px solid #ccc" : openDropdown === "maxDesiredPrice" ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                                onFocus={() => setOpenDropdown("maxDesiredPrice")}
                                name="maxDesiredPrice"
                                type="text"
                                className={`seller-form-properties-input`}
                                value={maxDesiredPrice}
                                onChange={(e) => {
                                    if (e.target.value.length === 0) setMaxDesiredPrice('');
                                    else setMaxDesiredPrice(Number(e.target.value.replace(/,/g, '')).toLocaleString('en-US'))
                                }}
                                required
                            />
                        </div>
                    </div>
                    <div className="seller-form-properties-form-group">
                        <div className="seller-form-properties-form-item">
                            <label>Relationship with Property<span className="contact-us-modal-required">*</span></label>
                            <CustomDropdown
                                openDropdown={openDropdown}
                                setOpenDropdown={setOpenDropdown}
                                name="relationshipWithProperty"
                                options={relationshipWithPropertyOptions}
                                value={relationshipWithProperty}
                                setValue={setRelationshipWithProperty}
                                identifier={"relationshipWithProperty"}
                            />
                        </div>
                        <div className="seller-form-properties-form-item">
                            <label>Selling Time<span className="contact-us-modal-required">*</span></label>
                            <CustomDropdown
                                openDropdown={openDropdown}
                                setOpenDropdown={setOpenDropdown}
                                name="sellingTime"
                                options={sellingTimeOptions}
                                value={sellingTime}
                                setValue={setSellingTime}
                                identifier={"sellingTime"}
                            />
                        </div>
                    </div>
                    <div className="seller-form-properties-form-group">
                        <button
                            className="seller-form-properties-submit"
                            type="submit"
                            disabled={isFormValid === false}
                            style={{ cursor: isFormValid ? "pointer" : "auto", backgroundColor: isFormValid ? "#007bff" : "#bacdda" }}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="thank-you-modal-title"
                aria-describedby="thank-you-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        textAlign: 'center',
                        position: 'relative', // Added to position the close icon
                        py: 5,
                        border: "none",
                    }}
                >
                    {/* Close icon at the top right */}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <h3 className="thank-you-modal-title" variant="h6" component="h2">
                        Thank You
                    </h3>
                    <p className="thank-you-modal-description" >
                        Your request has been successfully submitted and a Vihara team member will contact you shortly about your inquiry.
                    </p>
                    <p className="thank-you-modal-description">
                    Be sure to check out the Resources page to learn more about how we make auctions easier for you.
                    </p>
                    <p className="thank-you-modal-description">
                        Need support? <a href="mailto:trisha@vihara.ai">Go here instead.</a>
                    </p>
                </Box>
            </Modal>
        </div>
    );
};
const CustomDropdown = ({ options, placeholder, setValue, value, openDropdown, setOpenDropdown, identifier }) => {
    const isOpen = openDropdown === identifier;
    const handleDropdownToggle = () => {
        if (isOpen) {
            setOpenDropdown("na");
        } else {
            setOpenDropdown(identifier);
        }
    };
    const handleOptionClick = (option) => {
        setValue(option);
        setOpenDropdown("na");
    };

    return (
        <div className="sell-property-custom-dropdown">
            <div
                style={{ backgroundColor:(openDropdown === "") || (value !== "") ? "" : openDropdown === identifier ? "" : "#ffe6e6",border: (openDropdown === "") || (value !== "") ? "1px solid #ccc" : openDropdown === identifier ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                className="sell-property-custom-dropdown-header" onClick={handleDropdownToggle}>
                <span>{value || placeholder}</span>
                <FontAwesomeIcon
                    icon={isOpen ? faCaretUp : faCaretDown}
                    className="sell-property-custom-dropdown-icon"
                />
            </div>
            {isOpen && (
                <ul className="sell-property-custom-dropdown-list">
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className="sell-property-custom-dropdown-list-item"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
const MortageDropdown = ({ options, setMortgageExist, value, mortageRange, setMortageRange, openDropdown, setOpenDropdown, identifier }) => {
    const isOpen = openDropdown === identifier;
    const handleDropdownToggle = () => {
        if (isOpen) {
            setOpenDropdown("na");
        } else {
            setOpenDropdown(identifier);
        }
    };
    const inputRef = useRef(null);
    const handleOptionClick = (option) => {
        setMortgageExist(option);
        if (option === "Yes" && mortageRange === '') {
            inputRef.current.style.border = '1px solid rgb(3, 132, 251)';
            return;
        }
        setOpenDropdown(null);
    };
    const handleInputChange = (e) => {
        // Remove non-numeric characters
        if (e.target.value.length === 0) {
            setMortageRange('');
            return;
        }
        const value = e.target.value.replace(/,/g, '');

        // Convert to a number and format with commas
        const formattedValue = Number(value).toLocaleString('en-US');

        setMortageRange(formattedValue);
    };
    return (
        <div
            className="sell-property-custom-dropdown">
            <div
                style={{ backgroundColor: (openDropdown === "") || (value !== "") ? "" : openDropdown === identifier ? "" : "#ffe6e6",border: (openDropdown === "") || (value !== "") ? "1px solid #ccc" : openDropdown === identifier ? "1px solid rgb(3, 132, 251)" : "1px solid red" }}
                className="sell-property-custom-dropdown-header" onClick={handleDropdownToggle}>
                <span>{value === "Yes" ? `${value}, $${mortageRange}` : value}</span>
                <FontAwesomeIcon
                    icon={isOpen ? faCaretUp : faCaretDown}
                    className="sell-property-custom-dropdown-icon"
                />
            </div>
            {isOpen && (
                <ul className="sell-property-custom-dropdown-list">
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className="sell-property-custom-dropdown-list-item"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </li>
                    ))}
                    <li className='mortage-input-container' ><span>If Yes How Much?</span>
                        <div className='mortage-cutom-drop-down-input-container'
                            ref={inputRef}
                        >
                            <span>$</span>
                            <input
                                className='mortage-cutom-drop-down-input'
                                type="text"
                                value={mortageRange}
                                onChange={handleInputChange}
                                onFocus={() => inputRef.current.style.border = '1px solid rgb(3, 132, 251)'}
                            ></input>
                        </div>
                    </li>
                </ul>
            )}
        </div>
    );
};
const SellProperties = () => {
    return (
        <>
            <CommonHeroSection title="Looking to sell <br/> your property?" description="Vihara can help you sell your property quickly and easily." faqImage={Sell} />
            <div className="sell-properties-container">
                <TakeAMoment />
                <SellDetailForm />
            </div>
        </>
    )
}
export default SellProperties;
